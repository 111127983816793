import * as React from "react";

// axios
import { useAxios } from "../../hooks/useAxios";

// styling
import "./Style.scss";

// toast
import { toast, Slide } from "react-toastify";

// backdrop component
import BackDrop from "../../components/BackDrop.js";

// mui
import {
  Typography,
  Button,
  useScrollTrigger,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  Input,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  IconButton,
  InputAdornment,
  TextField,
  OutlinedInput,
  InputLabel,
  ListItemText,
} from "@mui/material";

// mui material icons
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { Delete } from "@mui/icons-material";

// mui date pickers
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";

// mui icons
import FileDownloadIcon from "@mui/icons-material/FileDownload";

// filter component
import UnitWisePerformanceFilter from "../../components/Filter/UnitWisePerformanceFilter";

// utility
import { dates } from "../../index";

// moment library to handle dates
import moment from "moment";

// excel utility
import ExcelDownload from "../../components/ExcelFormat/ExcelFileDownload";

// export headers options
const head = [
  {
    field: "all",
    headerName: "ALL",
    width: 100,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "hospital_name",
    headerName: "Hospital Name",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "total_tl_vhv",
    headerName: "Total (TL + VHV)",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "total_no_of_camps",
    headerName: "No. of Camps",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "auto_publicity",
    headerName: "Auto Publicity",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "meeting_cosponsor",
    headerName: "Meeting Co-Sponsor",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "base_hospital_vc_visit",
    headerName: "Base Hospital/VC Visit",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "dbcs_office_visit",
    headerName: "DBCS Office Visit",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "leave_count",
    headerName: "Leave",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "comp_off_count",
    headerName: "Comp Off",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "week_off_count",
    headerName: "Weekly Off",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "declared_holiday_count",
    headerName: "Declared Holiday",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "event_not_entered",
    headerName: "Event Not Entered",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "late_in_early_out",
    headerName: "Late In Early Out",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "school_screening",
    headerName: "School Screening",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "industrial_screening",
    headerName: "Industrial Screening",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "total_no_of_distance_travelled",
    headerName: "No. of Distance Travelled",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "total_no_of_household_survey_done",
    headerName: "No. of Household Survey Done",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "total_no_of_individuals_screened",
    headerName: "No. of Individuals Screened",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "total_no_of_yc_issued",
    headerName: "No. of Yellow Cards Issued",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "total_no_of_pc_issued",
    headerName: "No. of Pink Cards Issued",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "total_no_of_yc_reported_at_camp",
    headerName: "No. of Yellow Cards Reported at Camp",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "total_no_of_pc_collected_at_vision_center",
    headerName: "No. of Pink Cards Collected at Vision Center",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "total_no_of_yc_selected_for_surgery",
    headerName: "No. of Yellow Cards Selected for Surgery",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "total_no_of_pc_selected_for_surgery",
    headerName: "No. of Pink Cards Selected for Surgery",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "total_no_of_yc_converted_to_gov_surgery",
    headerName: "No. of Yellow Cards Converted to GOV Surgery",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "total_no_of_pc_converted_to_gov_surgery",
    headerName: "No. of Pink Cards Converted to GOV Surgery",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
];

// utility functions
const renderInputs = (props, placeholder, classname) => (
  <Input
    sx={{
      width: "100%",
      input: {
        "&::placeholder": {
          // <----- Add this.
          opacity: 1,
          // marginBottom: '-5px',
          paddingLeft: "10px",
          fontSize: "13px",
          color: "#000000",
        },
        width: "100%",
      },
      backgroundColor: "#ffffff",
      // borderRadius: '5px',
      // maxHeight: '50px'
    }}
    type="text"
    className={classname}
    inputRef={props.inputRef}
    // inputProps={props.inputProps}
    inputProps={{
      ...props.inputProps,
      placeholder: placeholder,
    }}
    value={props.value}
    onClick={props.onClick}
    onChange={props.onChange}
    endAdornment={props.InputProps?.endAdornment}
  />
);

// component
function UnitWisePerformance() {
  // axios interceptors
  const { axios } = useAxios();

  // grid headers
  const columns = [
    {
      field: "sno",
      headerName: "S.No",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "hospital_name",
      headerName: "Hospital Name",
      width: 160,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Typography
          style={{ textDecoration: "underline", cursor: "pointer" }}
          title={`Additional Information about the ${alterScreenName(
            params?.value
          )} unit`}
          onClick={() => {
            console.log("params.row", params);
            setUnitDetailsPopup(true);
            setUnitBasedFwDetails(params?.row);
            setUnitwiseFwSearchDropdown((prevState) => ({
              ...prevState,
              fwIds: params?.row?.field_worker_ids ?? [],
            }));
          }}
        >
          {params?.value}
        </Typography>
      ),
    },
    {
      field: "total_tl_vhv",
      headerName: "Total (TL + VHV)",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_no_of_camps",
      headerName: "No. of Camps",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "auto_publicity",
      headerName: "Auto Publicity",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "meeting_cosponsor",
      headerName: "Meeting Co-Sponsor",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "base_hospital_vc_visit",
      headerName: "Base Hospital/VC Visit",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "dbcs_office_visit",
      headerName: "DBCS Office Visit",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "leave_count",
      headerName: "Leave",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "comp_off_count",
      headerName: "Comp Off",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "week_off_count",
      headerName: "Weekly Off",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "declared_holiday_count",
      headerName: "Declared Holiday",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "event_not_entered",
      headerName: "Event Not Entered",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "late_in_early_out",
      headerName: "Late In Early Out",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "school_screening",
      headerName: "School Screening",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "industrial_screening",
      headerName: "Industrial Screening",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_no_of_distance_travelled",
      headerName: "No. of Distance Travelled",
      width: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_no_of_household_survey_done",
      headerName: "No. of Household Survey Done",
      width: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_no_of_individuals_screened",
      headerName: "No. of Individuals Screened",
      width: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_no_of_yc_issued",
      headerName: "No. of Yellow Cards Issued",
      width: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_no_of_pc_issued",
      headerName: "No. of Pink Cards Issued",
      width: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_no_of_yc_reported_at_camp",
      headerName: "No. of Yellow Cards Reported at Camp",
      width: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_no_of_pc_collected_at_vision_center",
      headerName: "No. of Pink Cards Collected at Vision Center",
      width: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_no_of_yc_selected_for_surgery",
      headerName: "No. of Yellow Cards Selected for Surgery",
      width: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_no_of_pc_selected_for_surgery",
      headerName: "No. of Pink Cards Selected for Surgery",
      width: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_no_of_yc_converted_to_gov_surgery",
      headerName: "No. of Yellow Cards Converted to GOV Surgery",
      width: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_no_of_pc_converted_to_gov_surgery",
      headerName: "No. of Pink Cards Converted to GOV Surgery",
      width: 250,
      headerClassName: "super-app-theme--header",
    },
  ];

  // unitwise fieldworkers details headers
  const unitwiseFwColumns = [
    {
      field: "sno",
      headerName: "S.No",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "field_worker_name",
      headerName: "Fieldworker Name",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_tl_vhv",
      headerName: "Total (TL + VHV)",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_no_of_camps",
      headerName: "No. of Camps",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "auto_publicity",
      headerName: "Auto Publicity",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "meeting_cosponsor",
      headerName: "Meeting Co-Sponsor",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "base_hospital_vc_visit",
      headerName: "Base Hospital/VC Visit",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "dbcs_office_visit",
      headerName: "DBCS Office Visit",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "leave_count",
      headerName: "Leave",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "comp_off_count",
      headerName: "Comp Off",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "week_off_count",
      headerName: "Weekly Off",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "declared_holiday_count",
      headerName: "Declared Holiday",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "event_not_entered",
      headerName: "Event Not Entered",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "late_in_early_out",
      headerName: "Late In Early Out",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "school_screening",
      headerName: "School Screening",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "industrial_screening",
      headerName: "Industrial Screening",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_no_of_distance_travelled",
      headerName: "No. of Distance Travelled",
      width: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_no_of_household_survey_done",
      headerName: "No. of Household Survey Done",
      width: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_no_of_individuals_screened",
      headerName: "No. of Individuals Screened",
      width: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_no_of_yc_issued",
      headerName: "No. of Yellow Cards Issued",
      width: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_no_of_pc_issued",
      headerName: "No. of Pink Cards Issued",
      width: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_no_of_yc_reported_at_camp",
      headerName: "No. of Yellow Cards Reported at Camp",
      width: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_no_of_pc_collected_at_vision_center",
      headerName: "No. of Pink Cards Collected at Vision Center",
      width: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_no_of_yc_selected_for_surgery",
      headerName: "No. of Yellow Cards Selected for Surgery",
      width: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_no_of_pc_selected_for_surgery",
      headerName: "No. of Pink Cards Selected for Surgery",
      width: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_no_of_yc_converted_to_gov_surgery",
      headerName: "No. of Yellow Cards Converted to GOV Surgery",
      width: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_no_of_pc_converted_to_gov_surgery",
      headerName: "No. of Pink Cards Converted to GOV Surgery",
      width: 250,
      headerClassName: "super-app-theme--header",
    },
  ];

  // loader state
  const [backDropOpen, setBackDropOpen] = React.useState(false);

  // filter initial state
  const [filterValue, setFilterValue] = React.useState({
    campStart: "",
    campEnd: "",
    hospital_name: "",
  });

  // excel download popup state management
  const [isDownPopup, setIsDownPopup] = React.useState(false);
  const [dateSearch, setDateSearch] = React.useState("");
  const [searchValue, setSearchValue] = React.useState({
    eventStart: "",
    eventEnd: "",
  });
  const [headerValue, setHeaderValue] = React.useState(head);
  const [value, setValue] = React.useState([]);

  // data state
  const [rows, setRows] = React.useState([]);

  // initial pagination state
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });
  const [length, setLength] = React.useState(0);

  // unitwise fieldworkers related state managment

  // unitwise fieldworkers pagination state
  const [unitwiseFwLength, setUnitwiseFwLength] = React.useState(0);
  const [unitwiseFwPaginationModel, setUnitwiseFwPaginationModel] =
    React.useState({ pageSize: 25, page: 0 });

  // unitwise fieldworkers (selected units details) state management
  const [unitBasedFwDetails, setUnitBasedFwDetails] = React.useState({});

  // unitwise fieldworkers details (dialog) state management (utility)
  const [unitDetailsPopup, setUnitDetailsPopup] = React.useState(false);

  // unitwise fieldworkers data state
  const [unitwiseFwRows, setUnitwiseFwRows] = React.useState([]);

  // unitwise fieldworkers filter state
  const [unitwiseFwSearchDropdown, setUnitwiseFwSearchDropdown] =
    React.useState({
      fwIds: [],
      fwDetails: [],
    });

  // unitwise fieldworkers dropdown name
  const [unitwiseFwName, setUnitwiseFwName] = React.useState([]);

  // unitwise fieldworkers selected ids
  const [unitwiseFwIds, setUnitwiseFwIds] = React.useState([]);

  // fetching datas
  React.useEffect(() => {
    fetchUnitWisePerformanceList();
  }, [filterValue, paginationModel, head]);

  // fetching uniwise fieldworkers list
  React.useEffect(() => {
    if (unitwiseFwSearchDropdown?.fwIds.length > 0) {
      fetchUnitWiseFieldWorkersList();
    }
  }, [unitwiseFwSearchDropdown?.fwIds]);

  // fetching unitwise fieldworkers details list
  React.useEffect(() => {
    if (unitwiseFwIds.length > 0) {
      fetchUnitWiseFieldWorkersDetailsList();
    } else {
      setUnitwiseFwRows([]);
      setUnitwiseFwLength(0);
    }
  }, [unitwiseFwIds, unitwiseFwPaginationModel]);

  // fetching UnitWisePerformanceList list
  async function fetchUnitWisePerformanceList() {
    try {
      const response = await axios.get(`UnitWisePerformanceReport`, {
        params: {
          fromDate: filterValue.campStart,
          toDate: filterValue.campEnd,
          hospital_name: filterValue.hospital_name,
          limit: paginationModel.pageSize,
          page: paginationModel.page + 1,
        },
      });
      console.log("unitWisePerformance response", response);
      const listLength = response?.data?.result?.length || 0;
      if (listLength > 0) {
        setLength(listLength);
        const result = response?.data?.result?.data?.map(
          ({ _id: id, total_no_of_distance_travelled, ...rest }, index) => ({
            id,
            sno: (index += 1),
            total_no_of_distance_travelled: Math.round(
              total_no_of_distance_travelled
            ),
            ...rest,
          })
        );
        result.pop();
        setRows(result);
      } else {
        setRows([]);
        setLength(0);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  // fetching UnitWiseFieldWorkersList list
  async function fetchUnitWiseFieldWorkersList() {
    try {
      const response = await axios.get(`getFieldWorker`, {
        params: { field_worker_ids: unitwiseFwSearchDropdown?.fwIds },
      });
      console.log("getFieldWorker response", response);
      const { result, success } = response?.data;
      if (success) {
        let fwDetails = [];
        if (result?.length > 0) {
          fwDetails = [
            { _id: "SelectAll", user_name: "SelectAll", isChecked: false },
          ];
          result?.map(({ _id, user_name }) => {
            fwDetails?.push({
              _id,
              user_name: iniCapUtilHandler(user_name),
              isChecked: false,
            });
          });
        }
        setUnitwiseFwSearchDropdown((prevState) => ({
          ...prevState,
          fwDetails: fwDetails,
        }));
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  // fetching unitwise fieldworkers details list
  async function fetchUnitWiseFieldWorkersDetailsList() {
    try {
      let response = await axios.get(`UnitWiseWorkerPerformanceReport`, {
        params: {
          field_worker_ids: unitwiseFwIds,
          limit: unitwiseFwPaginationModel.pageSize,
          page: unitwiseFwPaginationModel.page + 1,
        },
      });
      console.log("UnitWiseWorkerPerformanceReport response", response);
      const listLength = response?.data?.result?.length || 0;

      if (listLength > 0) {
        setUnitwiseFwLength(listLength);
        const result = response?.data?.result?.data?.map(
          ({ total_no_of_distance_travelled, ...rest }, index) => ({
            id: Math.random().toString(36).substr(2, 9),
            sno: (index += 1),
            total_no_of_distance_travelled: Math.round(
              total_no_of_distance_travelled
            ),
            ...rest,
          })
        );
        setUnitwiseFwRows(result);
      } else {
        setUnitwiseFwRows([]);
        setUnitwiseFwLength(0);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  // excel down api req
  async function handleExcelDownload() {
    try {
      setBackDropOpen(true);
      const response = await axios.get(
        `UnitWisePerformanceReport?fromDate=${searchValue.eventStart}&toDate=${
          searchValue.eventEnd
        }&hospital_name=${filterValue.hospital_name}&limit=&page=${1}`
      );
      console.log("unitWisePerformance response", response);
      const listLength = response?.data?.result?.length || 0;
      if (listLength > 0) {
        const responseData = response?.data?.result?.data?.map(
          ({ total_no_of_distance_travelled, ...rest }) => ({
            total_no_of_distance_travelled: Math.round(
              total_no_of_distance_travelled
            ),
            ...rest,
          })
        );
        console.log("responseData", responseData);

        if (value.length > 1) {
          value.sort((x, y) => x.position - y.position);
        }
        let endResult = [];
        responseData?.map((row) => {
          let filteredObj = {};
          value?.map((val) => {
            if (Array.isArray(row[val.value])) {
              filteredObj[val.header] = row[val.value]
                ?.map((val) => val)
                .toString();
            } else {
              filteredObj[val.header] = row[val.value];
            }
          });
          endResult.push(filteredObj);
        });
        console.log("endResult", endResult);
        ExcelDownload(endResult, "UnitWisePerformanceReport");
        setIsDownPopup((k) => !k);
        setDateSearch("");
        setValue([]);
        setBackDropOpen(false);
      } else {
        setBackDropOpen(false);
        toast.warning("No Data Found", {
          autoClose: 1500,
          transition: Slide,
        });
      }
    } catch (e) {
      console.log("e", e);
      setBackDropOpen(false);
    }
  }

  // excel down api req (fieldworkers details)
  async function handleUnitwiseFwExcel() {
    try {
      setBackDropOpen(true);
      let response = await axios.get(`UnitWiseWorkerPerformanceReport`, {
        params: {
          field_worker_ids: unitwiseFwIds,
          limit: unitwiseFwPaginationModel.pageSize,
          page: unitwiseFwPaginationModel.page + 1,
        },
      });
      console.log("UnitWiseWorkerPerformanceReportExport response", response);
      const listLength = response?.data?.result?.length || 0;

      if (listLength > 0) {
        const result = response?.data?.result?.data?.map(
          (
            {
              field_worker_name,
              total_tl_vhv,
              total_no_of_camps,
              auto_publicity,
              meeting_cosponsor,
              base_hospital_vc_visit,
              dbcs_office_visit,
              leave_count,
              comp_off_count,
              week_off_count,
              declared_holiday_count,
              event_not_entered,
              late_in_early_out,
              school_screening,
              industrial_screening,
              total_no_of_distance_travelled,
              total_no_of_household_survey_done,
              total_no_of_individuals_screened,
              total_no_of_yc_issued,
              total_no_of_pc_issued,
              total_no_of_yc_reported_at_camp,
              total_no_of_pc_collected_at_vision_center,
              total_no_of_yc_selected_for_surgery,
              total_pink_cards_selected_for_surgery,
              total_no_of_yc_converted_to_gov_surgery,
              total_no_of_pc_converted_to_gov_surgery,
            },
            index
          ) => ({
            Sno: (index += 1),
            FieldWorkerName: iniCapUtilHandler(field_worker_name),
            TotalTLVHV: total_tl_vhv,
            TotalNoOfCamps: total_no_of_camps,
            AutoPublicity: auto_publicity,
            MeetingCoSponsor: meeting_cosponsor,
            BaseHospitalVcVisit: base_hospital_vc_visit,
            DBCSOfficeVisit: dbcs_office_visit,
            Leave: leave_count,
            CompOff: comp_off_count,
            WeekOff: week_off_count,
            DeclaredHoliday: declared_holiday_count,
            EventNotEntered: event_not_entered,
            LateInEarlyOut: late_in_early_out,
            SchoolScreening: school_screening,
            IndustrialScreening: industrial_screening,
            TotalNoOfDistanceTravelled: Math.round(
              total_no_of_distance_travelled
            ),
            TotalNoOfHouseHoldSurveyDone: total_no_of_household_survey_done,
            TotalNoOfIndividualsScreened: total_no_of_individuals_screened,
            TotalNoOfYellowCardsIssued: total_no_of_yc_issued,
            TotalNoOfPinkCardsIssued: total_no_of_pc_issued,
            TotalNoOfYellowCardsReportedAtCamp: total_no_of_yc_reported_at_camp,
            TotalNoOfPinkCardsCollectedAtVisionCenter:
              total_no_of_pc_collected_at_vision_center,
            TotalNoOfYellowCardsSelectedForSurgery:
              total_no_of_yc_selected_for_surgery,
            TotalNoOfPinkCardsSelectedForSurgery:
              total_pink_cards_selected_for_surgery,
            TotalNoOfYellowCardsConvertedToGovSurgery:
              total_no_of_yc_converted_to_gov_surgery,
            TotalNoOfPinkCardsConvertedToGovSurgery:
              total_no_of_pc_converted_to_gov_surgery,
          })
        );
        ExcelDownload(result, "UnitWiseFieldWorkersReport");
        setBackDropOpen(false);
      } else {
        setBackDropOpen(false);
        toast.warning("No Data Found", {
          autoClose: 1500,
          transition: Slide,
        });
      }
    } catch (error) {
      console.log("error", error);
      setBackDropOpen(false);
    }
  }

  // filter utility functions
  const filteredObjectArray = (val) => {
    setFilterValue(val);
    setPaginationModel({ ...paginationModel, page: 0 });
  };

  // grid utility functions
  const CustomToolBar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarContainer />
        {/* <GridToolbarFilterButton /> */}
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  };

  // handle excel download popup dates utility functions
  const handleDates = (val) => {
    const today = new Date(); // Get the current date
    setDateSearch(val);

    // Format the dates as "DD-MM-YYYY"
    var dateFormat = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    if (val == "last_week") {
      //==============last week ======================

      const lastWeek = new Date(today);
      lastWeek.setDate(today.getDate() - 7); // Subtract 7 days to get the date for last week

      // Find the start date (Monday) of last week
      const startOfWeek = new Date(lastWeek);
      startOfWeek.setDate(lastWeek.getDate() - ((lastWeek.getDay() + 6) % 7));

      // Find the end date (Sunday) of last week
      const endOfWeek = new Date(lastWeek);
      endOfWeek.setDate(lastWeek.getDate() - ((lastWeek.getDay() + 6) % 7) + 6);

      // Format the dates
      var formattedStartOfWeek = startOfWeek.toISOString().split("T")[0];
      var formattedEndOfWeek = endOfWeek.toISOString().split("T")[0];

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: formattedStartOfWeek,
        eventEnd: formattedEndOfWeek,
      }));
    } else if (val == "last_month") {
      //================last month ===============================
      const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);

      // Set the day to 1 to get the first day of the previous month
      lastMonth.setDate(2);

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: lastMonth.toISOString().split("T")[0],
        eventEnd: moment().date(0).toISOString().split("T")[0],
      }));
    } else if (val == "last_3_month") {
      //================last month ===============================

      const threeMonthsAgo = new Date(
        today.getFullYear(),
        today.getMonth() - 3,
        1
      );

      // Set the day to 1 to get the first day of three months ago
      threeMonthsAgo.setDate(1);

      const lastDayOfThreeMonthsAgo = new Date(
        today.getFullYear(),
        today.getMonth(),
        0
      );

      const formattedStartDate = threeMonthsAgo.toLocaleDateString("en-IN", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        timeZone: "Asia/Kolkata",
      });
      const formattedEndDate = lastDayOfThreeMonthsAgo.toLocaleDateString(
        "en-IN",
        {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          timeZone: "Asia/Kolkata",
        }
      );

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: formattedStartDate
          .replaceAll("/", "-")
          .split("-")
          .reverse()
          .join("-"),
        eventEnd: formattedEndDate
          .replaceAll("/", "-")
          .split("-")
          .reverse()
          .join("-"),
      }));
    } else if (val == "all") {
      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: "",
        eventEnd: "",
      }));
    }
  };

  // handle excel downlaod popup checkbox headers
  const handleChange = (e, val, position, headerName) => {
    const index = value?.findIndex((x) => x.value == val);
    console.log("index", index);
    if (e.target.checked) {
      if (index < 0) {
        if (headerName == "ALL") {
          let valueReference = [];
          head.forEach((data, index) => {
            data.isChecked = true;
            var values = {
              position: index,
              value: data?.field,
              header: data?.headerName,
            };
            valueReference.push(values);
            console.log("valueReference", valueReference);
            let sliceData = valueReference.slice(1);
            setValue(sliceData);
          });
          console.log("value", value);
        } else {
          head[position].isChecked = true;
          setValue(() => {
            var values = { position: position, value: val, header: headerName };
            var data = { ...value, values };
            console.log("value------", value);
            return Object.values(data);
          });
        }
      } else {
        // value.splice(index, 1);
        if (headerName == "ALL") {
          head.forEach((data, index) => {
            data.isChecked = false;
          });
          setValue([]);
        } else {
          head[position].isChecked = false;
          value.splice(index, 1);
          setHeaderValue(head);
        }
      }
    } else {
      // value.splice(index, 1);
      if (headerName == "ALL") {
        head.forEach((data, index) => {
          data.isChecked = false;
        });
        setValue([]);
      } else {
        let headReference = [...head];
        headReference[position].isChecked = false;
        value.splice(index, 1);
        setHeaderValue(headReference);
      }
    }
  };

  // utility functions
  const alterScreenName = (name) => {
    var menu_name = name.replaceAll("_", " ");
    var name = menu_name?.replace(/^.{1}/g, menu_name[0].toUpperCase());
    return name;
  };

  // handle unitwise fieldworkers details dialog (utility)
  const handleUnitDetailPopupClose = () => {
    setUnitDetailsPopup(false);
    setUnitBasedFwDetails({});
    setUnitwiseFwSearchDropdown((prevState) => ({
      ...prevState,
      fwIds: [],
      fwDetails: [],
    }));
    setUnitwiseFwName([]);
    setUnitwiseFwIds([]);
    setUnitwiseFwRows([]);
    setUnitwiseFwLength(0);
  };

  // handle unitwise filter reset (utility)
  const handleUnitwiseFwFilterReset = () => {
    setUnitwiseFwName([]);
    setUnitwiseFwIds([]);
    setUnitwiseFwRows([]);
    let updateIsCheckedUtil = unitwiseFwSearchDropdown?.fwDetails?.map(
      ({ isChecked, ...rest }) => ({
        ...rest,
        isChecked: false,
      })
    );
    setUnitwiseFwSearchDropdown((prevState) => ({
      ...prevState,
      fwDetails: updateIsCheckedUtil,
    }));
    setUnitwiseFwLength(0);
  };

  // handle dropdown checkbox (utility)
  const handleUnitwiseCheckboxChange = (event, fwName, position) => {
    let {
      target: { checked },
    } = event;

    let fwIds = [...unitwiseFwIds];
    let updateIsChecked;

    if (fwName === "SelectAll") {
      if (checked) {
        updateIsChecked = unitwiseFwSearchDropdown?.fwDetails?.map(
          ({ isChecked, ...rest }) => ({ ...rest, isChecked: true })
        );

        fwIds = unitwiseFwSearchDropdown?.fwDetails
          ?.map(({ _id }) => _id)
          .slice(1);
      } else {
        fwIds = [];
        updateIsChecked = unitwiseFwSearchDropdown?.fwDetails?.map(
          ({ isChecked, ...rest }) => ({ ...rest, isChecked: false })
        );
      }
    } else {
      if (checked) {
        updateIsChecked = unitwiseFwSearchDropdown?.fwDetails?.map(
          (fwd, index) => {
            if (index == position) {
              return { ...fwd, isChecked: true };
            } else {
              return { ...fwd };
            }
          }
        );
        fwIds.push(unitwiseFwSearchDropdown?.fwDetails[position]._id);
      } else {
        updateIsChecked = unitwiseFwSearchDropdown?.fwDetails?.map(
          (fwd, index) => {
            if (index == position) {
              return { ...fwd, isChecked: false };
            } else {
              return { ...fwd };
            }
          }
        );
        fwIds = fwIds.filter(
          (id) => id !== unitwiseFwSearchDropdown?.fwDetails[position]._id
        );
      }
    }

    setUnitwiseFwIds(fwIds);
    setUnitwiseFwSearchDropdown((prevState) => ({
      ...prevState,
      fwDetails: updateIsChecked,
    }));

    const newFwNames = fwIds?.map(
      (id) =>
        unitwiseFwSearchDropdown?.fwDetails?.find(({ _id }) => id == _id)?._id
    );

    setUnitwiseFwName(newFwNames);
  };

  // utility handlers
  const iniCapUtilHandler = (string = null) => {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    return string;
  };

  return (
    <div className="main-report">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px 0",
        }}
      >
        <Typography variant="h5" color="secondary" className="text-left">
          Unit Wise Performance Report
        </Typography>
        <Button
          sx={{ textTransform: "capitalize", width: "auto", margin: "0 10px" }}
          disabled={rows?.length == 0}
          size="small"
          variant="contained"
          startIcon={<FileDownloadIcon />}
          onClick={() => {
            head.forEach((data) => {
              data.isChecked = false;
            });
            setSearchValue({
              eventStart: "",
              eventEnd: "",
            });
            setIsDownPopup((k) => !k);
          }}
        >
          Excel Download
        </Button>
      </div>

      {/* unit wise report filter */}
      <div>
        <UnitWisePerformanceFilter
          filterObject={(val) => filteredObjectArray(val)}
        />
      </div>

      {/* excel downlaod container */}
      {isDownPopup && (
        <div className="tabbackground">
          <div className="downloadtab">
            <div className="table">
              <Table>
                <TableHead>
                  <tr>
                    <th> </th>
                    <th>Columns </th>
                  </tr>
                </TableHead>
              </Table>
              <div style={{ display: "none" }} className="filter">
                <FormControl style={{ width: "90%" }} size="small">
                  <Select
                    labelId="select-label"
                    id="select"
                    variant="standard"
                    label="Select Dates"
                    value={dateSearch}
                    onChange={(e) => {
                      handleDates(e.target.value);
                    }}
                  >
                    {dates?.map((option) => (
                      <MenuItem key={option.id} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {dateSearch == "custom" && (
                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "45%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="from_date"
                          value={
                            searchValue.eventStart == ""
                              ? null
                              : searchValue.eventStart
                          }
                          inputFormat="DD/MM/YYYY"
                          onChange={(e) => {
                            const selectedDate = e.$d;
                            if (selectedDate) {
                              setSearchValue({
                                ...searchValue,
                                eventStart: selectedDate,
                              });
                            } else {
                              setSearchValue({
                                ...searchValue,
                                eventStart: null,
                              });
                            }
                          }}
                          slotProps={{
                            actionBar: {
                              actions: ["clear"],
                            },
                          }}
                          renderInput={(props) =>
                            renderInputs(props, "From date", "date")
                          }
                        />
                      </LocalizationProvider>
                    </div>
                    <div style={{ width: "45%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="from_date"
                          value={
                            searchValue.eventEnd == ""
                              ? null
                              : searchValue.eventEnd
                          }
                          inputFormat="DD/MM/YYYY"
                          onChange={(e) => {
                            setSearchValue({ ...searchValue, eventEnd: e.$d });
                          }}
                          renderInput={(props) =>
                            renderInputs(props, "To date", "date")
                          }
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                )}
              </div>
              <Table>
                <TableBody>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                  {headerValue?.map((head, index) => {
                    if (head.headerName !== "S no") {
                      return (
                        <TableRow key={index}>
                          <TableCell sx={{ textAlign: "center", width: "25%" }}>
                            <Checkbox
                              checked={head.isChecked}
                              onChange={(e) => {
                                handleChange(
                                  e,
                                  head.field,
                                  index,
                                  head.headerName
                                );
                              }}
                            />
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {alterScreenName(head?.headerName)}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  })}
                </TableBody>
              </Table>
            </div>
            <div className="downloadoptions">
              <Button
                variant="contained"
                onClick={() => {
                  setIsDownPopup((k) => !k);
                  setDateSearch("");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={/* dateSearch == "" ||  */ value.length == 0}
                onClick={() => handleExcelDownload()}
              >
                Download
              </Button>{" "}
            </div>
          </div>
        </div>
      )}

      {/* UnitWisePerformance report datas */}
      <div className="table">
        <DataGrid
          localeText={{
            noRowsLabel: "Reports are processing. Please hold on for a moment.",
          }}
          style={{ height: "75vh", width: "95vw" }}
          className="table-pagination"
          rowCount={length}
          page={paginationModel.page}
          sx={{
            boxShadow: 2,
            overflowX: "auto",
            border: 2,
            textTransform: "capitalize",
            borderColor: "primary.light",
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            "& .super-app-theme--header": {
              backgroundColor: "LightBlue",
            },
          }}
          // autoHeight
          rows={rows}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          slots={{ toolbar: CustomToolBar }}
          paginationMode="server"
          disableDensitySelector
          initialState={{
            columns: {
              columnVisibilityModel: {},
            },
          }}
          hideFooterSelectedRowCount={true}
        />
      </div>

      {/* unitwise fieldworkers details dialog */}
      <Dialog fullScreen open={unitDetailsPopup}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleUnitDetailPopupClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {`Fieldworker information for the ${
                unitBasedFwDetails?.hospital_name
                  ? alterScreenName(unitBasedFwDetails?.hospital_name)
                  : ""
              } unit`}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          {/* search and export block */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1rem",
            }}
          >
            <div>
              <FormControl sx={{ width: 500 }} size="small">
                <InputLabel id="demo-multiple-checkbox-label">
                  Select Field Workers
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={unitwiseFwName}
                  input={<OutlinedInput label="Select Field Workers" />}
                  renderValue={(selected) =>
                    selected
                      ?.map(
                        (selectedId) =>
                          unitwiseFwSearchDropdown?.fwDetails?.find(
                            ({ _id }) => _id == selectedId
                          )?.user_name
                      )
                      .join(", ")
                  }
                >
                  <MenuItem disabled value="">
                    Select Field Workers
                  </MenuItem>
                  {unitwiseFwSearchDropdown?.fwDetails?.map((fwd, index) => (
                    <MenuItem key={fwd?._id} value={fwd?._id}>
                      <Checkbox
                        checked={fwd?.isChecked}
                        onChange={(event) => {
                          handleUnitwiseCheckboxChange(
                            event,
                            fwd?.user_name,
                            index
                          );
                        }}
                      />
                      <ListItemText primary={fwd?.user_name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Button
                sx={{ textTransform: "capitalize" }}
                onClick={handleUnitwiseFwFilterReset}
                style={{
                  cursor: "pointer",
                  textTransform: "capitalize",
                  marginLeft: "1rem",
                }}
                variant="contained"
                startIcon={<Delete />}
              >
                Reset
              </Button>
            </div>
            <div>
              <Button
                sx={{
                  textTransform: "capitalize",
                  width: "auto",
                  margin: "0 10px",
                }}
                disabled={unitwiseFwRows?.length == 0}
                size="small"
                variant="contained"
                startIcon={<FileDownloadIcon />}
                onClick={handleUnitwiseFwExcel}
              >
                Excel Download
              </Button>
            </div>
          </div>

          {/* unitwise fieldworkers list block */}
          <DataGrid
            localeText={{
              noRowsLabel:
                "Please select the fieldworkers to generate the report.",
            }}
            style={{ height: "75vh", width: "95vw" }}
            className="table-pagination"
            rowCount={unitwiseFwLength}
            page={unitwiseFwPaginationModel.page}
            sx={{
              boxShadow: 2,
              overflowX: "auto",
              border: 2,
              textTransform: "capitalize",
              borderColor: "primary.light",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
              "& .super-app-theme--header": {
                backgroundColor: "LightBlue",
              },
            }}
            rows={unitwiseFwRows}
            columns={unitwiseFwColumns}
            paginationModel={unitwiseFwPaginationModel}
            onPaginationModelChange={setUnitwiseFwPaginationModel}
            slots={{ toolbar: CustomToolBar }}
            paginationMode="server"
            disableDensitySelector
            initialState={{
              columns: {
                columnVisibilityModel: {},
              },
            }}
            hideFooterSelectedRowCount={true}
          />
        </DialogContent>
      </Dialog>

      {/* backdrop */}
      <BackDrop open={backDropOpen} />
    </div>
  );
}

export default UnitWisePerformance;
