import * as React from "react";

// axios
import { useAxios } from "../../../hooks/useAxios";

// styling
import "../Style.scss";

import BackDrop from "../../../components/BackDrop.js";

// toast
import { toast, Slide } from "react-toastify";

// mui
import {
  Typography,
  Button,
  useScrollTrigger,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  Input,
} from "@mui/material";

// mui date pickers
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";

// mui icons
import FileDownloadIcon from "@mui/icons-material/FileDownload";

// filter component
import MasterReportFilterVs from "../../../components/Filter/MasterReportFilterVs";

// utility
import { dates } from "../../../index";

// moment library to handle dates
import moment from "moment";

// excel utility
import ExcelDownload from "../../../components/ExcelFormat/ExcelFileDownload";

// export headers options
const head = [
  {
    field: "all",
    headerName: "ALL",
    width: 100,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "hospital_name_s",
    headerName: "Hospital Name",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "project_s",
    headerName: "Project",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "screening_no_s",
    headerName: "Screening No",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "screening_place_s",
    headerName: "Screening Place",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "screening_from_date_s",
    headerName: "Screening From Date",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "screening_to_date_s",
    headerName: "Screening To Date",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "camp_date_s",
    headerName: "Camp Date",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "fieldworker_name_s",
    headerName: "Fieldworker Name",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "fieldworker_type_s",
    headerName: "Fieldworker Type",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "month_s",
    headerName: "Month",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "entry_date_s",
    headerName: "Entry Date",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "door_status_s",
    headerName: "Door Status",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "willing_for_screening_s",
    headerName: "Willing For Screening",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "total_no_of_members_in_family_s",
    headerName: "Total No of Members in Family",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "available_no_of_members_in_family_s",
    headerName: "Available No of Members in Family",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "state_name_s",
    headerName: "State Name",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "district_name_s",
    headerName: "District Name",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "taluk_name_s",
    headerName: "Taluk Name",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "panchayat_name_s",
    headerName: "Panchayat Name",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "village_name_s",
    headerName: "Village Name",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "patient_name_s",
    headerName: "Patient Name",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "door_no_s",
    headerName: "Door No",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "occupation_s",
    headerName: "Occupation",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "family_income_s",
    headerName: "Family Income",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "first_time_vision_test_s",
    headerName: "First Time Vision Test",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "mobile_number_s",
    headerName: "Mobile Number",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "id_proof_s",
    headerName: "Id Proof",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "id_proof_number_s",
    headerName: "Id Proof Number",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "gender_s",
    headerName: "Gender",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "age_s",
    headerName: "Age",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "systemic_illness_s",
    headerName: "Systemic Illness",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "history_of_hypertension_s",
    headerName: "History of Hypertension",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "history_of_diabetes_s",
    headerName: "History of Diabetes",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "visual_acuity_re_s",
    headerName: "Visual Acuity (Right Eye)",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "visual_acuity_le_s",
    headerName: "Visual Acuity (Left Eye)",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "already_wearing_glass_s",
    headerName: "Already Wearing Glass",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "history_of_eye_surgery_done_re_s",
    headerName: "History of Eye Surgery Done (Right Eye)",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "history_of_eye_surgery_done_le_s",
    headerName: "History of Eye Surgery Done (Left Eye)",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "hospital_surgery_done_re_s",
    headerName: "Hospital Surgery Done (Right Eye)",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "hospital_surgery_done_le_s",
    headerName: "Hospital Surgery Done (Left Eye)",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "patient_latitude_s",
    headerName: "Patient Latitude",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "patient_longitude_s",
    headerName: "Patient Longitude",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "referral_id_s",
    headerName: "Referral Id",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "camp_no_optem",
    headerName: "Camp No",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "complaints_optem",
    headerName: "Complaints",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "defective_vision_re_optem",
    headerName: "Defective Vision (Right Eye)",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "defective_vision_le_optem",
    headerName: "Defective Vision (Left Eye)",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "already_wearing_glass_optem",
    headerName: "Already Wearing Glass (Optem)",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "advised_to_optem",
    headerName: "Advised To",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "frame_size_optem",
    headerName: "Frame Size",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "distant_vision_re_sph_optem",
    headerName: "Distant Vision (RE-Sph)",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "distant_vision_re_cyl_optem",
    headerName: "Distant Vision (RE-Cyl)",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "distant_vision_re_axis_optem",
    headerName: "Distant Vision (RE-Axis)",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "re_bcva_optem",
    headerName: "RE-BCVA",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "distant_vision_le_sph_optem",
    headerName: "Distant Vision (LE-Sph)",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "distant_vision_le_cyl_optem",
    headerName: "Distant Vision (LE-Cyl)",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "distant_vision_le_axis_optem",
    headerName: "Distant Vision (LE-Axis)",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "le_bcva_optem",
    headerName: "LE-BCVA",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "sugar_level_optem",
    headerName: "Sugar Level (mg/dl)",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "blood_pressure_optem",
    headerName: "Blood Pressure (in mm/hg)",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "surgery_fitness_optem",
    headerName: "Surgery Fitness",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "reported_status_wp",
    headerName: "Reported Status",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "surgery_done_status_wp",
    headerName: "Surgery Done Status",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "post_op_vision_after_surgery_re_wp",
    headerName: "Post-op Vision after Surgery (RE)",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "post_op_vision_after_surgery_le_wp",
    headerName: "Post-op Vision after Surgery (LE)",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "gp_received_status_wp",
    headerName: "GP Distributed Status",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "intervention_vision_after_gp_re_wp",
    headerName: "Intervention Vision after GP (RE)",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
  {
    field: "intervention_vision_after_gp_le_wp",
    headerName: "Intervention Vision after GP (LE)",
    width: 160,
    headerClassName: "super-app-theme--header",
    isChecked: false,
  },
];

// utility functions
const renderInputs = (props, placeholder, classname) => (
  <Input
    sx={{
      width: "100%",
      input: {
        "&::placeholder": {
          // <----- Add this.
          opacity: 1,
          // marginBottom: '-5px',
          paddingLeft: "10px",
          fontSize: "13px",
          color: "#000000",
        },
        width: "100%",
      },
      backgroundColor: "#ffffff",
      // borderRadius: '5px',
      // maxHeight: '50px'
    }}
    type="text"
    className={classname}
    inputRef={props.inputRef}
    // inputProps={props.inputProps}
    inputProps={{
      ...props.inputProps,
      placeholder: placeholder,
    }}
    value={props.value}
    onClick={props.onClick}
    onChange={props.onChange}
    endAdornment={props.InputProps?.endAdornment}
  />
);

function MasterReport() {
  // axios interceptors
  const { axios } = useAxios();

  // grid headers
  const columns = [
    {
      field: "sno",
      headerName: "S no",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "hospital_name_s",
      headerName: "Hospital Name",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "project_s",
      headerName: "Project",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "screening_no_s",
      headerName: "Screening No",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "screening_place_s",
      headerName: "Screening Place",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "screening_from_date_s",
      headerName: "Screening From Date",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "screening_to_date_s",
      headerName: "Screening To Date",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "camp_date_s",
      headerName: "Camp Date",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "fieldworker_name_s",
      headerName: "Fieldworker Name",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "fieldworker_type_s",
      headerName: "Fieldworker Type",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "month_s",
      headerName: "Month",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "entry_date_s",
      headerName: "Entry Date",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "door_status_s",
      headerName: "Door Status",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "willing_for_screening_s",
      headerName: "Willing For Screening",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_no_of_members_in_family_s",
      headerName: "Total No of Members in Family",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "available_no_of_members_in_family_s",
      headerName: "Available No of Members in Family",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "state_name_s",
      headerName: "State Name",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "district_name_s",
      headerName: "District Name",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "taluk_name_s",
      headerName: "Taluk Name",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "panchayat_name_s",
      headerName: "Panchayat Name",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "village_name_s",
      headerName: "Village Name",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "patient_name_s",
      headerName: "Patient Name",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "door_no_s",
      headerName: "Door No",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "occupation_s",
      headerName: "Occupation",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "family_income_s",
      headerName: "Family Income",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "first_time_vision_test_s",
      headerName: "First Time Vision Test",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "mobile_number_s",
      headerName: "Mobile Number",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "id_proof_s",
      headerName: "Id Proof",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "id_proof_number_s",
      headerName: "Id Proof Number",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "gender_s",
      headerName: "Gender",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "age_s",
      headerName: "Age",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "systemic_illness_s",
      headerName: "Systemic Illness",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "history_of_hypertension_s",
      headerName: "History of Hypertension",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "history_of_diabetes_s",
      headerName: "History of Diabetes",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "visual_acuity_re_s",
      headerName: "Visual Acuity (Right Eye)",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "visual_acuity_le_s",
      headerName: "Visual Acuity (Left Eye)",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "already_wearing_glass_s",
      headerName: "Already Wearing Glass",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "history_of_eye_surgery_done_re_s",
      headerName: "History of Eye Surgery Done (Right Eye)",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "history_of_eye_surgery_done_le_s",
      headerName: "History of Eye Surgery Done (Left Eye)",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "hospital_surgery_done_re_s",
      headerName: "Hospital Surgery Done (Right Eye)",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "hospital_surgery_done_le_s",
      headerName: "Hospital Surgery Done (Left Eye)",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "patient_latitude_s",
      headerName: "Patient Latitude",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "patient_longitude_s",
      headerName: "Patient Longitude",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "referral_id_s",
      headerName: "Referral Id",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "camp_no_optem",
      headerName: "Camp No",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "complaints_optem",
      headerName: "Complaints",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "defective_vision_re_optem",
      headerName: "Defective Vision (Right Eye)",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "defective_vision_le_optem",
      headerName: "Defective Vision (Left Eye)",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "already_wearing_glass_optem",
      headerName: "Already Wearing Glass (Optem)",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "advised_to_optem",
      headerName: "Advised To",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "frame_size_optem",
      headerName: "Frame Size",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "distant_vision_re_sph_optem",
      headerName: "Distant Vision (RE-Sph)",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "distant_vision_re_cyl_optem",
      headerName: "Distant Vision (RE-Cyl)",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "distant_vision_re_axis_optem",
      headerName: "Distant Vision (RE-Axis)",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "re_bcva_optem",
      headerName: "RE-BCVA",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "distant_vision_le_sph_optem",
      headerName: "Distant Vision (LE-Sph)",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "distant_vision_le_cyl_optem",
      headerName: "Distant Vision (LE-Cyl)",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "distant_vision_le_axis_optem",
      headerName: "Distant Vision (LE-Axis)",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "le_bcva_optem",
      headerName: "LE-BCVA",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "sugar_level_optem",
      headerName: "Sugar Level (mg/dl)",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "blood_pressure_optem",
      headerName: "Blood Pressure (in mm/hg)",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "surgery_fitness_optem",
      headerName: "Surgery Fitness",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "reported_status_wp",
      headerName: "Reported Status",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "surgery_done_status_wp",
      headerName: "Surgery Done Status",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "post_op_vision_after_surgery_re_wp",
      headerName: "Post-op Vision after Surgery (RE)",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "post_op_vision_after_surgery_le_wp",
      headerName: "Post-op Vision after Surgery (LE)",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "gp_received_status_wp",
      headerName: "GP Distributed Status",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "intervention_vision_after_gp_re_wp",
      headerName: "Intervention Vision after GP (RE)",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "intervention_vision_after_gp_le_wp",
      headerName: "Intervention Vision after GP (LE)",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
  ];

  // sample data
  /* const sample = [
    {
      id: 1,
      hospital_name_s: "Test Hospital",
      project_s: "Test Project",
      screening_no_s: "12345",
      screening_from_date_s: "2024-04-28",
      screening_to_date_s: "2024-04-30",
      camp_date_s: "2024-05-01",
      fieldworker_name_s: "Fieldworker Name",
      fieldworker_type_s: "Fieldworker Type",
      month_s: "January",
      entry_date_s: "2024-04-29",
      door_status_s: "Open",
      willing_for_screening_s: "Yes",
      total_no_of_members_in_family_s: 4,
      available_no_of_members_in_family_s: 3,
      state_name_s: "Test State",
      district_name_s: "Test District",
      taluk_name_s: "Test Taluk",
      panchayat_name_s: "Test Panchayat",
      village_name_s: "Test Village",
      door_no_s: "123A",
      mobile_number_s: "1234567890",
      id_proof_s: "Aadhar Card",
      id_proof_number_s: "ABCDE1234F",
      gender_s: "Male",
      age_s: 30,
      systemic_illness_s: "None",
      history_of_hypertension_s: "No",
      history_of_diabetes_s: "No",
      visual_acuity_re_s: "6/6",
      visual_acuity_le_s: "6/6",
      already_wearing_glass_s: "No",
      history_of_eye_surgery_done_re_s: "No",
      history_of_eye_surgery_done_le_s: "No",
      hospital_surgery_done_re_s: "No",
      hospital_surgery_done_le_s: "No",
      patient_latitude_s: 40.7128,
      patient_longitude_s: -74.006,
      referral_id_s: "REF123",
      camp_no_optem: 123,
      complaints_optem: "None",
      defective_vision_re_optem: "Yes",
      defective_vision_le_optem: "No",
      already_wearing_glass_optem: "Yes",
      advised_to_optem: "General",
      frame_size_optem: "Medium",
      distant_vision_re_sph_optem: "+1.00",
      distant_vision_re_cyl_optem: "-0.50",
      distant_vision_re_axis_optem: "90",
      re_bcva_optem: "6/6",
      distant_vision_le_sph_optem: "+1.25",
      distant_vision_le_cyl_optem: "-0.75",
      distant_vision_le_axis_optem: "75",
      le_bcva_optem: "6/6",
      sugar_level_optem: "Normal",
      blood_pressure_optem: "120/80",
      surgery_fitness_optem: "Fit",
      reported_status_wp: "Reported",
      surgery_done_status_wp: "Done",
      gp_received_status_wp: "Received",
    },
    {
      id: 2,
      hospital_name_s: "Sample Hospital",
      project_s: "Sample Project",
      screening_no_s: "54321",
      screening_from_date_s: "2024-05-01",
      screening_to_date_s: "2024-05-03",
      camp_date_s: "2024-05-04",
      fieldworker_name_s: "Another Fieldworker",
      fieldworker_type_s: "Assistant",
      month_s: "February",
      entry_date_s: "2024-05-02",
      door_status_s: "Closed",
      willing_for_screening_s: "No",
      total_no_of_members_in_family_s: 3,
      available_no_of_members_in_family_s: 2,
      state_name_s: "Sample State",
      district_name_s: "Sample District",
      taluk_name_s: "Sample Taluk",
      panchayat_name_s: "Sample Panchayat",
      village_name_s: "Sample Village",
      door_no_s: "456B",
      mobile_number_s: "9876543210",
      id_proof_s: "Passport",
      id_proof_number_s: "FGHIJ5678K",
      gender_s: "Female",
      age_s: 25,
      systemic_illness_s: "High blood pressure",
      history_of_hypertension_s: "Yes",
      history_of_diabetes_s: "Yes",
      visual_acuity_re_s: "6/9",
      visual_acuity_le_s: "6/9",
      already_wearing_glass_s: "Yes",
      history_of_eye_surgery_done_re_s: "Yes",
      history_of_eye_surgery_done_le_s: "No",
      hospital_surgery_done_re_s: "Yes",
      hospital_surgery_done_le_s: "No",
      patient_latitude_s: 34.0522,
      patient_longitude_s: -118.2437,
      referral_id_s: "REF456",
      camp_no_optem: 456,
      complaints_optem: "Headache",
      defective_vision_re_optem: "No",
      defective_vision_le_optem: "Yes",
      already_wearing_glass_optem: "No",
      advised_to_optem: "Specialist",
      frame_size_optem: "Large",
      distant_vision_re_sph_optem: "+2.00",
      distant_vision_re_cyl_optem: "-1.25",
      distant_vision_re_axis_optem: "180",
      re_bcva_optem: "6/12",
      distant_vision_le_sph_optem: "+1.75",
      distant_vision_le_cyl_optem: "-1.50",
      distant_vision_le_axis_optem: "135",
      le_bcva_optem: "6/12",
      sugar_level_optem: "High",
      blood_pressure_optem: "140/90",
      surgery_fitness_optem: "Unfit",
      reported_status_wp: "Not Reported",
      surgery_done_status_wp: "Not Done",
      gp_received_status_wp: "Not Received",
    },
  ]; */

  // filter initial state
  const [filterValue, setFilterValue] = React.useState({
    campStart: "",
    campEnd: "",
    hospital_name: "",
    name: "",
    camp_num: "",
    // camp_place: '',
    district: "",
  });

  // excel download popup state management
  const [isDownPopup, setIsDownPopup] = React.useState(false);
  const [dateSearch, setDateSearch] = React.useState("");
  const [searchValue, setSearchValue] = React.useState({
    eventStart: "",
    eventEnd: "",
  });
  const [headerValue, setHeaderValue] = React.useState(head);
  const [value, setValue] = React.useState([]);

  // hospital/unit id
  const [hospital, setHospital] = React.useState("");

  // district data filter state
  const [district, setDistrict] = React.useState([]);

  // data state
  const [rows, setRows] = React.useState([]);

  // initial pagination state
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });
  const [length, setLength] = React.useState(0);

  // loader state
  const [backDropOpen, setBackDropOpen] = React.useState(false);

  // fetching datas
  React.useEffect(() => {
    fetchMasterList();
  }, [filterValue, paginationModel, head]);

  // fetching hospital based district
  React.useEffect(() => {
    if (hospital !== "") {
      fetchDistrict();
    }
  }, [hospital]);

  // fetching master list
  async function fetchMasterList() {
    try {
      const response = await axios.get(`CbbfEndReport`, {
        params: {
          fromDate: filterValue.campStart,
          toDate: filterValue.campEnd,
          hospital_name: filterValue.hospital_name,
          field_worker_name: filterValue.name,
          camp_number: filterValue.camp_num,
          district: filterValue.district,
          limit: paginationModel.pageSize,
          page: paginationModel.page + 1,
        },
      });
      console.log("masterReport response", response);
      const listLength = response?.data?.result?.length || 0;
      if (listLength > 0) {
        setLength(listLength);
        const result = response?.data?.result?.list?.map(
          (
            {
              slNo_servis: id,
              hospital_name_s,
              project_s,
              screening_place_s,
              fieldworker_name_s,
              screening_from_date_s,
              screening_to_date_s,
              camp_date_s,
              month_s,
              entry_date_s,
              patient_latitude_s,
              patient_longitude_s,
              door_status_s,
              willing_for_screening_s,
              already_wearing_glass_s,
              reported_status_wp,
              surgery_done_status_wp,
              gp_received_status_wp,
              first_time_vision_test_s,
              ...rest
            },
            index
          ) => ({
            id,
            sno: (index += 1),
            hospital_name_s: iniCapUtilHandler(hospital_name_s),
            project_s: iniCapUtilHandler(project_s),
            screening_place_s: iniCapUtilHandler(screening_place_s),
            fieldworker_name_s: iniCapUtilHandler(fieldworker_name_s),
            screening_from_date_s: utilityHandler(screening_from_date_s),
            screening_to_date_s: utilityHandler(screening_to_date_s || ""),
            camp_date_s: utilityHandler(camp_date_s || ""),
            month_s: utilityHandler(month_s || "", "month_s"),
            entry_date_s: utilityHandler(entry_date_s || ""),
            patient_latitude_s: utilityHandler(
              patient_latitude_s || "",
              "patient_latitude_s"
            ),
            patient_longitude_s: utilityHandler(
              patient_longitude_s || "",
              "patient_latitude_s"
            ),
            door_status_s: iniCapUtilHandler(door_status_s),
            willing_for_screening_s: iniCapUtilHandler(willing_for_screening_s),
            already_wearing_glass_s: iniCapUtilHandler(already_wearing_glass_s),
            reported_status_wp: iniCapUtilHandler(reported_status_wp),
            surgery_done_status_wp: iniCapUtilHandler(surgery_done_status_wp),
            gp_received_status_wp: iniCapUtilHandler(gp_received_status_wp),
            first_time_vision_test_s: iniCapUtilHandler(
              first_time_vision_test_s
            ),
            ...rest,
          })
        );
        console.log("result", result);
        setRows(result);
      } else {
        setRows([]);
        setLength(0);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  // fetching districts
  async function fetchDistrict() {
    try {
      axios
        .post(`HospitalBasedDistrictDropDown`, {
          hospital_id: hospital,
        })
        .then((res) => {
          console.log("data", res);
          var data = res?.data?.result[0].district;
          setDistrict(data);
        })
        .catch((err) => {
          console.log("endReport_District", err);
        });
    } catch (error) {
      console.log("endReport_District", error);
    }
  }

  // excel down api req
  const handleExcelDownload = async () => {
    setBackDropOpen(true);
    const response = await axios.get(
      `CbbfEndMyReport?fromDate=${searchValue.eventStart}&toDate=${
        searchValue.eventEnd
      }&hospital_name=${filterValue.hospital_name}&field_worker_name=${
        filterValue.name
      }&camp_number=${filterValue.camp_num}&district=${
        filterValue.district
      }&limit=&page=${1}`,
      { responseType: "blob" }
    );

    console.log("response", response);

    // Create a Blob from the response data
    const blob = new Blob([response?.data], {
      type: response?.data?.type,
    });

    // Create a URL for the Blob and trigger the download
    const blobUrl = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = blobUrl;
    anchor.download = "VolunteersMasterReport.xlsx";
    document.body.appendChild(anchor);
    anchor.click();
    anchor.remove();
    window.URL.revokeObjectURL(blobUrl);
    setBackDropOpen(false);

    /* const listLength = response?.data?.result?.length || 0;
    if (listLength > 0) {
      const responseData = response?.data?.result?.list;
      const finalData = responseData?.map(
        ({
          hospital_name_s,
          project_s,
          screening_place_s,
          fieldworker_name_s,
          screening_from_date_s,
          screening_to_date_s,
          camp_date_s,
          month_s,
          entry_date_s,
          patient_latitude_s,
          patient_longitude_s,
          door_status_s,
          willing_for_screening_s,
          already_wearing_glass_s,
          reported_status_wp,
          surgery_done_status_wp,
          gp_received_status_wp,
          first_time_vision_test_s,
          ...rest
        }) => ({
          hospital_name_s: iniCapUtilHandler(hospital_name_s),
          project_s: iniCapUtilHandler(project_s),
          screening_place_s: iniCapUtilHandler(screening_place_s),
          fieldworker_name_s: iniCapUtilHandler(fieldworker_name_s),
          screening_from_date_s: utilityHandler(screening_from_date_s),
          screening_to_date_s: utilityHandler(screening_to_date_s || ""),
          camp_date_s: utilityHandler(camp_date_s || ""),
          month_s: utilityHandler(month_s || "", "month_s"),
          entry_date_s: utilityHandler(entry_date_s || ""),
          patient_latitude_s: utilityHandler(
            patient_latitude_s || "",
            "patient_latitude_s"
          ),
          patient_longitude_s: utilityHandler(
            patient_longitude_s || "",
            "patient_latitude_s"
          ),
          door_status_s: iniCapUtilHandler(door_status_s),
          willing_for_screening_s: iniCapUtilHandler(willing_for_screening_s),
          already_wearing_glass_s: iniCapUtilHandler(already_wearing_glass_s),
          reported_status_wp: iniCapUtilHandler(reported_status_wp),
          surgery_done_status_wp: iniCapUtilHandler(surgery_done_status_wp),
          gp_received_status_wp: iniCapUtilHandler(gp_received_status_wp),
          first_time_vision_test_s: iniCapUtilHandler(first_time_vision_test_s),
          ...rest,
        })
      );
      console.log("finalData*", finalData);
      if (value.length > 1) {
        value.sort((x, y) => x.position - y.position);
      }
      let endResult = [];
      finalData?.map((row) => {
        let filteredObj = {};
        value?.map((val) => {
          if (Array.isArray(row[val.value])) {
            filteredObj[val.header] = row[val.value]
              ?.map((val) => val)
              .toString();
          } else {
            filteredObj[val.header] = row[val.value];
          }
        });
        endResult.push(filteredObj);
      });
      console.log("endResult", endResult);
      ExcelDownload(endResult, "VolunteersMasterReport");
      setIsDownPopup((k) => !k);
      setDateSearch("");
      setValue([]);
    } else {
      toast.warning("No Data Found", {
        autoClose: 1500,
        transition: Slide,
      });
    } */
  };

  // hospital based district id handler
  const callHospitalBasedDist = (val) => {
    setHospital(val);
    setDistrict([]);
  };

  // filter utility functions
  const filteredObjectArray = (val) => {
    setFilterValue(val);
    setPaginationModel({ ...paginationModel, page: 0 });
  };

  // grid utility functions
  const CustomToolBar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarContainer />
        {/* <GridToolbarFilterButton /> */}
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  };

  // handle excel download popup dates utility functions
  const handleDates = (val) => {
    const today = new Date(); // Get the current date
    setDateSearch(val);

    // Format the dates as "DD-MM-YYYY"
    var dateFormat = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    if (val == "last_week") {
      //==============last week ======================

      const lastWeek = new Date(today);
      lastWeek.setDate(today.getDate() - 7); // Subtract 7 days to get the date for last week

      // Find the start date (Monday) of last week
      const startOfWeek = new Date(lastWeek);
      startOfWeek.setDate(lastWeek.getDate() - ((lastWeek.getDay() + 6) % 7));

      // Find the end date (Sunday) of last week
      const endOfWeek = new Date(lastWeek);
      endOfWeek.setDate(lastWeek.getDate() - ((lastWeek.getDay() + 6) % 7) + 6);

      // Format the dates
      var formattedStartOfWeek = startOfWeek.toISOString().split("T")[0];
      var formattedEndOfWeek = endOfWeek.toISOString().split("T")[0];

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: formattedStartOfWeek,
        eventEnd: formattedEndOfWeek,
      }));
    } else if (val == "last_month") {
      //================last month ===============================
      const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);

      // Set the day to 1 to get the first day of the previous month
      lastMonth.setDate(2);

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: lastMonth.toISOString().split("T")[0],
        eventEnd: moment().date(0).toISOString().split("T")[0],
      }));
    } else if (val == "last_3_month") {
      //================last month ===============================

      const threeMonthsAgo = new Date(
        today.getFullYear(),
        today.getMonth() - 3,
        1
      );

      // Set the day to 1 to get the first day of three months ago
      threeMonthsAgo.setDate(1);

      const lastDayOfThreeMonthsAgo = new Date(
        today.getFullYear(),
        today.getMonth(),
        0
      );

      const formattedStartDate = threeMonthsAgo.toLocaleDateString("en-IN", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        timeZone: "Asia/Kolkata",
      });
      const formattedEndDate = lastDayOfThreeMonthsAgo.toLocaleDateString(
        "en-IN",
        {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          timeZone: "Asia/Kolkata",
        }
      );

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: formattedStartDate
          .replaceAll("/", "-")
          .split("-")
          .reverse()
          .join("-"),
        eventEnd: formattedEndDate
          .replaceAll("/", "-")
          .split("-")
          .reverse()
          .join("-"),
      }));
    } else if (val == "all") {
      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: "",
        eventEnd: "",
      }));
    }
  };

  // handle excel downlaod popup checkbox headers
  const handleChange = (e, val, position, headerName) => {
    const index = value?.findIndex((x) => x.value == val);
    console.log("index", index);
    if (e.target.checked) {
      if (index < 0) {
        if (headerName == "ALL") {
          let valueReference = [];
          head.forEach((data, index) => {
            data.isChecked = true;
            var values = {
              position: index,
              value: data?.field,
              header: data?.headerName,
            };
            valueReference.push(values);
            console.log("valueReference", valueReference);
            let sliceData = valueReference.slice(1);
            setValue(sliceData);
          });
          console.log("value", value);
        } else {
          head[position].isChecked = true;
          setValue(() => {
            var values = { position: position, value: val, header: headerName };
            var data = { ...value, values };
            console.log("value------", value);
            return Object.values(data);
          });
        }
      } else {
        // value.splice(index, 1);
        if (headerName == "ALL") {
          head.forEach((data, index) => {
            data.isChecked = false;
          });
          setValue([]);
        } else {
          head[position].isChecked = false;
          value.splice(index, 1);
          setHeaderValue(head);
        }
      }
    } else {
      // value.splice(index, 1);
      if (headerName == "ALL") {
        head.forEach((data, index) => {
          data.isChecked = false;
        });
        setValue([]);
      } else {
        let headReference = [...head];
        headReference[position].isChecked = false;
        value.splice(index, 1);
        setHeaderValue(headReference);
      }
    }
  };

  // utility functions
  const alterScreenName = (name) => {
    var menu_name = name.replaceAll("_", " ");
    var name = menu_name?.replace(/^.{1}/g, menu_name[0].toUpperCase());
    return name;
  };

  const utilityHandler = (value = "NA", column = null) => {
    if (value !== null && value !== "") {
      switch (column) {
        case "month_s":
          return moment(value).format("MMM");
          break;
        case "patient_latitude_s":
        case "patient_longitude_s":
          return value.slice(0, 10);
          break;
        default:
          return moment(value).format("YYYY-MM-DD");
          break;
      }
    }
    return (value = "NA");
  };

  const iniCapUtilHandler = (string = null) => {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    return string;
  };

  return (
    <div className="main-report">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px 0",
        }}
      >
        <Typography variant="h5" color="secondary" className="text-left">
          Volunteers Screening End To End Report
        </Typography>
        <Button
          sx={{ textTransform: "capitalize", width: "auto", margin: "0 10px" }}
          disabled={rows?.length == 0}
          size="small"
          variant="contained"
          startIcon={<FileDownloadIcon />}
          onClick={() => {
            head.forEach((data) => {
              data.isChecked = false;
            });
            setSearchValue({
              eventStart: "",
              eventEnd: "",
            });
            setIsDownPopup((k) => !k);
          }}
        >
          Excel Download
        </Button>
      </div>

      {/* master report filter */}
      <div>
        <MasterReportFilterVs
          filterObject={(val) => filteredObjectArray(val)}
          district={district}
          callHospitalBasedDist={(_id) => callHospitalBasedDist(_id)}
        />
      </div>

      {/* excel downlaod container */}
      {isDownPopup && (
        <div className="tabbackground">
          <div className="downloadtab">
            <div className="table">
              <Table>
                <TableHead>
                  <tr>
                    <th> </th>
                    <th>Columns </th>
                  </tr>
                </TableHead>
              </Table>
              <div className="filter">
                <FormControl style={{ width: "90%" }} size="small">
                  <Select
                    labelId="select-label"
                    id="select"
                    variant="standard"
                    label="Select Dates"
                    value={dateSearch}
                    onChange={(e) => {
                      handleDates(e.target.value);
                    }}
                  >
                    {dates?.map((option) => (
                      <MenuItem key={option.id} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {dateSearch == "custom" && (
                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "45%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="from_date"
                          value={
                            searchValue.eventStart == ""
                              ? null
                              : searchValue.eventStart
                          }
                          inputFormat="DD/MM/YYYY"
                          onChange={(e) => {
                            const selectedDate = e.$d;
                            if (selectedDate) {
                              setSearchValue({
                                ...searchValue,
                                eventStart: selectedDate,
                              });
                            } else {
                              setSearchValue({
                                ...searchValue,
                                eventStart: null,
                              });
                            }
                          }}
                          slotProps={{
                            actionBar: {
                              actions: ["clear"],
                            },
                          }}
                          renderInput={(props) =>
                            renderInputs(props, "From date", "date")
                          }
                        />
                      </LocalizationProvider>
                    </div>
                    <div style={{ width: "45%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="from_date"
                          value={
                            searchValue.eventEnd == ""
                              ? null
                              : searchValue.eventEnd
                          }
                          inputFormat="DD/MM/YYYY"
                          onChange={(e) => {
                            setSearchValue({ ...searchValue, eventEnd: e.$d });
                          }}
                          renderInput={(props) =>
                            renderInputs(props, "To date", "date")
                          }
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                )}
              </div>
              <Table>
                <TableBody>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                  {headerValue?.map((head, index) => {
                    if (head.headerName !== "S no") {
                      return (
                        <TableRow key={index}>
                          <TableCell sx={{ textAlign: "center", width: "25%" }}>
                            <Checkbox
                              checked={head.isChecked}
                              onChange={(e) => {
                                handleChange(
                                  e,
                                  head.field,
                                  index,
                                  head.headerName
                                );
                              }}
                            />
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {alterScreenName(head?.headerName)}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  })}
                </TableBody>
              </Table>
            </div>
            <div className="downloadoptions">
              <Button
                variant="contained"
                onClick={() => {
                  setIsDownPopup((k) => !k);
                  setDateSearch("");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={dateSearch == "" || value.length == 0}
                onClick={() => handleExcelDownload()}
              >
                Download
              </Button>{" "}
            </div>
          </div>
        </div>
      )}

      {/* master report datas */}
      <div className="table">
        <DataGrid
          localeText={{
            noRowsLabel: "Reports are processing. Please hold on for a moment.",
          }}
          style={{ height: "75vh", width: "95vw" }}
          className="table-pagination"
          rowCount={length}
          page={paginationModel.page}
          sx={{
            boxShadow: 2,
            overflowX: "auto",
            border: 2,
            textTransform: "capitalize",
            borderColor: "primary.light",
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            "& .super-app-theme--header": {
              backgroundColor: "LightBlue",
            },
          }}
          // autoHeight
          rows={rows}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          slots={{ toolbar: CustomToolBar }}
          paginationMode="server"
          disableDensitySelector
          initialState={{
            columns: {
              columnVisibilityModel: {
                referral_id_s: false,
              },
            },
          }}
          hideFooterSelectedRowCount={true}
        />
      </div>

      {/* backdrop */}
      <BackDrop open={backDropOpen} />
    </div>
  );
}

export default MasterReport;
