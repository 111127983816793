import React, { useEffect, useState } from "react";
import "../Style.scss";

// backdrop component
import BackDrop from "../../../components/BackDrop.js";

/*---- Components imports ----------- */
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useAxios } from "../../../hooks/useAxios";
import FieldWorkerFilter from "../../../components/Filter/FieldWorkerFilter";
import format from "date-fns/format";
import {
  Button,
  Typography,
  getRowIndexRelativeToVisibleRows,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ExcelDownload from "../../../components/ExcelFormat/ExcelFileDownload";
import SurveyReportFilter from "../../../components/Filter/SurveyReportFilter";
import moment from "moment";

import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
// import dates from '../../index.js'

import { dates } from "../../../index";

import {
  Table,
  makeStyles,
  FormControl,
  TableBody,
  TableCell,
  InputLabel,
  Input,
  Select,
  MenuItem,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";

const head = [
  // { field: 'id', headerName: 'S no', filterable: false, renderCell: (index) => index.api.getRowIndex(index.row.code) },
  {
    field: "lineNo",
    headerName: "S.No",
    flex: 0,
    editable: false,
    renderCell: (params: GridRenderCellParams<DatasetEntryEntity>) =>
      params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "ALL",
    headerName: "ALL",
    isChecked: false,
    width: 200,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "door_status",
    headerName: "Door Status",
    width: 200,
    isChecked: false,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "will_for_screen",
    headerName: "Willing for Screening",
    width: 160,
    isChecked: false,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "family_actual",
    headerName: " Family Members Actual ",
    width: 250,
    isChecked: false,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "family_present",
    headerName: "Family Members Present",
    width: 250,
    isChecked: false,
    headerClassName: "super-app-theme--header",
  },
  // { field: 'field_invg_code', headerName: 'Field Investigator Code', width: 190, headerClassName: 'super-app-theme--header', },
  {
    field: "state_name",
    headerName: "State Name",
    width: 150,
    isChecked: false,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "district_name",
    headerName: "District Name",
    width: 150,
    isChecked: false,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "taluk_name",
    headerName: "Taluk Name",
    width: 150,
    isChecked: false,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "mandal_name",
    headerName: "Panchayat Name",
    width: 150,
    isChecked: false,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "hospital_name",
    headerName: "Hospital Name",
    width: 150,
    isChecked: false,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "field_worker_name",
    headerName: "Fieldworker Name",
    width: 160,
    isChecked: false,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "camp_place",
    headerName: "Camp Place",
    width: 230,
    isChecked: false,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "patient_latitude",
    headerName: "Patient Latitude",
    width: 230,
    isChecked: false,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "patient_longitude",
    headerName: "Patient Longitude",
    width: 230,
    isChecked: false,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "village_name",
    headerName: "Village Name",
    width: 300,
    isChecked: false,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "camp_number",
    headerName: "Camp No.",
    width: 300,
    isChecked: false,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "mob_num",
    headerName: "Mobile Number",
    width: 300,
    isChecked: false,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "door_num",
    headerName: "Door No.",
    width: 300,
    isChecked: false,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "current_date",
    headerName: "Entry Date",
    width: 300,
    isChecked: false,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "slNo_servis",
    headerName: "S.No (by SERVIS)",
    width: 300,
    isChecked: false,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "name",
    headerName: "Patient Name",
    width: 200,
    isChecked: false,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "gender",
    headerName: "Gender",
    width: 130,
    isChecked: false,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "age",
    headerName: "Age",
    width: 130,
    isChecked: false,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "systemic_illess",
    headerName: "Systemic Illness",
    width: 200,
    isChecked: false,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "already_glass_wear",
    headerName: "Already Wearing Glass",
    width: 200,
    isChecked: false,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "cataract_left_eye",
    headerName: "Cataract Surgery Done Left Eye",
    width: 300,
    isChecked: false,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "cataract_right_eye",
    headerName: "Cataract Surgery Done Right Eye",
    width: 300,
    isChecked: false,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "visual_acuity_left",
    headerName: "Visual Acuity Left Eye",
    width: 300,
    isChecked: false,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "visual_acuity_right",
    headerName: "Visual Acuity Right Eye",
    width: 300,
    isChecked: false,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "history_hypertension",
    headerName: "History of Hypertension",
    width: 300,
    isChecked: false,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "history_diabetic",
    headerName: "History of Diabetic",
    width: 300,
    isChecked: false,
    headerClassName: "super-app-theme--header",
  },
];

const renderInputs = (props, placeholder, classname) => (
  <Input
    sx={{
      width: "100%",
      input: {
        "&::placeholder": {
          // <----- Add this.
          opacity: 1,
          // marginBottom: '-5px',
          paddingLeft: "10px",
          fontSize: "13px",
          color: "#000000",
        },
        width: "100%",
      },
      backgroundColor: "#ffffff",
      // borderRadius: '5px',
      // maxHeight: '50px'
    }}
    type="text"
    className={classname}
    inputRef={props.inputRef}
    // inputProps={props.inputProps}
    inputProps={{
      ...props.inputProps,
      placeholder: placeholder,
    }}
    value={props.value}
    onClick={props.onClick}
    onChange={props.onChange}
    endAdornment={props.InputProps?.endAdornment}
  />
);

const CbbfSurveyReport = () => {
  const dateFormat = process.env.REACT_APP_DATE_FORMAT;

  function CustomToolBar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarContainer />
        {/* <GridToolbarFilterButton /> */}
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  }

  const { axios } = useAxios();

  // loader state
  const [backDropOpen, setBackDropOpen] = React.useState(false);

  const [rows, setRows] = React.useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });
  const [filterValue, setFilterValue] = React.useState({
    campStart: "",
    campEnd: "",
    hospital_name: "",
    name: "",
    camp_num: "",
    // camp_place: '',
    district: "",
  });
  const [length, setLength] = React.useState(0);

  // hospital/unit id
  const [hospital, setHospital] = useState("");

  const [district, setDistrict] = useState([]);

  const [searchValue, setSearchValue] = React.useState({
    eventStart: "",
    eventEnd: "",
  });

  const [downloadTab, setDownloadTab] = useState(false);
  const [value, setValue] = useState([]);
  const [headerValue, setHeaderValue] = useState(head);
  const [dateSearch, setDateSearch] = useState("");

  const columns = [
    // { field: 'id', headerName: 'S no', filterable: false, renderCell: (index) => index.api.getRowIndex(index.row.code) },
    {
      field: "lineNo",
      headerName: "S.No",
      flex: 0,
      editable: false,
      renderCell: (params: GridRenderCellParams<DatasetEntryEntity>) =>
        params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "door_status",
      headerName: "Door Status",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "will_for_screen",
      headerName: "Willing for Screening",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "family_actual",
      headerName: " Family Members Actual ",
      width: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "family_present",
      headerName: "Family Members Present",
      width: 250,
      headerClassName: "super-app-theme--header",
    },
    // { field: 'field_invg_code', headerName: 'Field Investigator Code', width: 190, headerClassName: 'super-app-theme--header', },
    {
      field: "state_name",
      headerName: "State Name",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "district_name",
      headerName: "District Name",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "taluk_name",
      headerName: "Taluk Name",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "mandal_name",
      headerName: "Village Name",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "hospital_name",
      headerName: "Hospital Name",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "field_worker_name",
      headerName: "Fieldworker Name",
      width: 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "camp_place",
      headerName: "Camp Place",
      width: 230,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "patient_latitude",
      headerName: "Patient Latitude",
      width: 230,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "patient_longitude",
      headerName: "Patient Longitude",
      width: 230,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "village_name",
      headerName: "Village Name",
      width: 300,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "camp_number",
      headerName: "Camp No.",
      width: 300,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "mob_num",
      headerName: "Mobile Number",
      width: 300,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "door_num",
      headerName: "Door No.",
      width: 300,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "current_date",
      headerName: "Entry Date",
      width: 300,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "id",
      headerName: "S.No (by SERVIS)",
      width: 300,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "name",
      headerName: "Patient Name",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 130,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "age",
      headerName: "Age",
      width: 130,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "systemic_illess",
      headerName: "Systemic Illness",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "already_glass_wear",
      headerName: "Already Wearing Glass",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "cataract_left_eye",
      headerName: "Cataract Surgery Done Left Eye",
      width: 300,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "cataract_right_eye",
      headerName: "Cataract Surgery Done Right Eye",
      width: 300,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "visual_acuity_left",
      headerName: "Visual Acuity Left Eye",
      width: 300,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "visual_acuity_right",
      headerName: "Visual Acuity Right Eye",
      width: 300,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "history_hypertension",
      headerName: "History of Hypertension",
      width: 300,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "history_diabetic",
      headerName: "History of Diabetic",
      width: 300,
      headerClassName: "super-app-theme--header",
    },
  ];
  /*-------------   Rows -----------------------*/
  useEffect(() => {
    fetchData();
  }, [filterValue, paginationModel, head]);

  // fetching hospital based district
  useEffect(() => {
    if (hospital !== "") {
      fetchDistrict();
    }
  }, [hospital]);

  async function fetchData() {
    try {
      const res = await axios.post(
        `CbbfPatientReport?fromDate=${filterValue.campStart}&toDate=${
          filterValue.campEnd
        }&hospital_name=${filterValue.hospital_name}&field_worker_name=${
          filterValue.name
        }&camp_number=${filterValue.camp_num}&district=${
          filterValue.district
        }&limit=${paginationModel.pageSize}&page=${paginationModel.page + 1}`
      );

      var l = res.data.result?.length || 0;

      if (l == 0) {
        setRows([]);
        setLength(l);
      } else {
        setLength(l);
        let newArrayOfObj = res.data.result.list.map(
          ({ slNo_servis: id, _ids: index, ...rest }) => ({ id, ...rest })
        );
        console.log("newArrayOfObj", newArrayOfObj);
        let LatittudeOne = newArrayOfObj.map((data) => {
          return {
            ...data,
            patient_latitude: data.patient_latitude?.slice(0, 10),
          };
        });
        let LongitutdeOne = LatittudeOne.map((data) => {
          return {
            ...data,
            patient_longitude: data.patient_longitude?.slice(0, 10),
            current_date: data.current_date.slice(0, 10),
          };
        });

        console.log("LongitutdeOne", LongitutdeOne);
        setRows(LongitutdeOne);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async function fetchDistrict() {
    try {
      axios
        .post(`HospitalBasedDistrictDropDown`, {
          hospital_id: hospital,
        })
        .then((res) => {
          console.log("data", res);
          var data = res?.data?.result[0].district;
          setDistrict(data);
        })
        .catch((err) => {
          console.log("surverReport_District", err);
        });
    } catch (error) {
      console.log("surverReport_District", error);
    }
  }

  function firstLetter(s) {
    return s?.replace(/^.{1}/g, s[0].toUpperCase());
  }

  function alterScreenName(name) {
    var menu_name = name.replaceAll("_", " ");
    var name = menu_name?.replace(/^.{1}/g, menu_name[0].toUpperCase());
    return name;
  }

  const handleChange = (e, val, position, headerName) => {
    console.log("value", value);
    const index = value?.findIndex((x) => x.value == val);

    // const ind = headerValue

    // setHeaderValue((x) => {
    //   {
    //     ...x,
    //     isChecked : false
    //   }

    // })
    console.log("index", index);
    if (e.target.checked) {
      if (index < 0) {
        if (headerName == "ALL") {
          let valueReference = [];
          head.forEach((data, index) => {
            data.isChecked = true;
            var values = {
              position: index,
              value: data?.field,
              header: data?.headerName,
            };
            valueReference.push(values);
            console.log("valueReference", valueReference);
            let sliceData = valueReference.slice(2);
            setValue(sliceData);
            // setValue(() => {
            //   var values = {
            //     position: index,
            //     value: data?.field,
            //     header: data?.headerName,
            //   };
            //   var data = { ...value, values };
            //   let returnData = Object.values(data);
            //   console.log("returnData", returnData);
            //   return returnData;
            // });
          });
          console.log("value", value);
        } else {
          head[position].isChecked = true;
          setValue(() => {
            var values = { position: position, value: val, header: headerName };
            var data = { ...value, values };
            console.log("value------", value);
            return Object.values(data);
          });
        }
      } else {
        // value.splice(index, 1);
        if (headerName == "ALL") {
          head.forEach((data, index) => {
            data.isChecked = false;
          });
          setValue([]);
        } else {
          head[position].isChecked = false;
          value.splice(index, 1);
          setHeaderValue(head);
        }
      }
    } else {
      // value.splice(index, 1);
      if (headerName == "ALL") {
        head.forEach((data, index) => {
          data.isChecked = false;
        });
        setValue([]);
      } else {
        let headReference = [...head];
        headReference[position].isChecked = false;
        value.splice(index, 1);
        setHeaderValue(headReference);
      }
    }
  };

  function handleDates(val) {
    const today = new Date(); // Get the current date
    setDateSearch(val);

    // Format the dates as "DD-MM-YYYY"
    var dateFormat = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    if (val == "last_week") {
      //==============last week ======================

      const lastWeek = new Date(today);
      lastWeek.setDate(today.getDate() - 7); // Subtract 7 days to get the date for last week

      // Find the start date (Monday) of last week
      const startOfWeek = new Date(lastWeek);
      startOfWeek.setDate(lastWeek.getDate() - ((lastWeek.getDay() + 6) % 7));

      // Find the end date (Sunday) of last week
      const endOfWeek = new Date(lastWeek);
      endOfWeek.setDate(lastWeek.getDate() - ((lastWeek.getDay() + 6) % 7) + 6);

      // Format the dates
      var formattedStartOfWeek = startOfWeek.toISOString().split("T")[0];
      var formattedEndOfWeek = endOfWeek.toISOString().split("T")[0];

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: formattedStartOfWeek,
        eventEnd: formattedEndOfWeek,
      }));
    } else if (val == "last_month") {
      //================last month ===============================
      const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);

      // Set the day to 1 to get the first day of the previous month
      lastMonth.setDate(2);

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: lastMonth.toISOString().split("T")[0],
        eventEnd: moment().date(0).toISOString().split("T")[0],
      }));
    } else if (val == "last_3_month") {
      //================last month ===============================

      const threeMonthsAgo = new Date(
        today.getFullYear(),
        today.getMonth() - 3,
        1
      );

      // Set the day to 1 to get the first day of three months ago
      threeMonthsAgo.setDate(1);

      const lastDayOfThreeMonthsAgo = new Date(
        today.getFullYear(),
        today.getMonth(),
        0
      );

      const formattedStartDate = threeMonthsAgo.toLocaleDateString("en-IN", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        timeZone: "Asia/Kolkata",
      });
      const formattedEndDate = lastDayOfThreeMonthsAgo.toLocaleDateString(
        "en-IN",
        {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          timeZone: "Asia/Kolkata",
        }
      );

      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: formattedStartDate
          .replaceAll("/", "-")
          .split("-")
          .reverse()
          .join("-"),
        eventEnd: formattedEndDate
          .replaceAll("/", "-")
          .split("-")
          .reverse()
          .join("-"),
      }));
    } else if (val == "all") {
      setSearchValue((prevSearchDates) => ({
        ...prevSearchDates,
        eventStart: "",
        eventEnd: "",
      }));
    }
  }

  const handleuploadExcel = async () => {
    setBackDropOpen(true);
    // let newArrayOfObj = rows.map(({ id: slNo_servis, ...rest }) => ({ slNo_servis, ...rest }));
    const res = await axios.post(
      `CbbfPatientReport?fromDate=${searchValue.eventStart}&toDate=${
        searchValue.eventEnd
      }&hospital_name=${filterValue.hospital_name}&field_worker_name=${
        filterValue.name
      }&camp_number=${filterValue.camp_num}&district=${
        filterValue.district
      }&limit=&page=${1}`
    );

    const row = res?.data?.result?.list?.map((x) => {
      return {
        ...x,
        current_date: x?.current_date?.slice(0, 10),
      };
    });

    if (value.length > 1) {
      value.sort((x, y) => x.position - y.position);
    }

    var result = [];

    row?.map((x) => {
      var data = {};
      value?.map((i) => {
        data[i.header] = x[i.value];
      });
      result.push(data);
    });

    console.log("result", result);

    ExcelDownload(result, "VolunteersSurveyReport");
    setDownloadTab((k) => !k);
    setDateSearch("");
    setValue([]);
    setBackDropOpen(false);

    // let newArr = data?.map((obj) => {
    //     const newObj = { ...obj, hospital_name: firstLetter(obj?.hospital_name) };

    //     delete newObj["_id"]
    //     delete newObj["created_on"];
    //     delete newObj["modify_on"]
    //     return newObj;
    // })

    // let excelDownload = newArr.map(({
    //     door_status: DoorStatus,
    //     will_for_screen: WillForScreen,
    //     family_actual: ActualFamily,
    //     family_present: FamilyPresent,
    //     field_worker_name: FieldworkerName,
    //     state_name: StateName,
    //     district_name: DistrictName,
    //     taluk_name: TalukName,
    //     mandal_name: MandalName,
    //     hospital_name: HospitalName,
    //     camp_place: CampPlace,
    //     village_name: VillageName,
    //     camp_number: CampNumber,
    //     mob_num: MobileNumber,
    //     door_num: DoorNumber,
    //     current_date: EntryDate,
    //     slNo_servis: SlNoServis,
    //     name: Name,
    //     gender: Gender,
    //     age: Age,
    //     systemic_illess: SystemicIllness,
    //     already_glass_wear: AlreadywearGlass,
    //     cataract_left_eye: CataractLeftEye,
    //     visual_acuity_left: VisualAcuityLeft,
    //     visual_acuity_right: VisualAcuityRight,
    //     history_hypertension: HistoryOfHyperTension,
    //     history_diabetic: HistoryDiabetic,
    // }) => ({
    //     DoorStatus, WillForScreen, ActualFamily, HospitalName, FieldworkerName, StateName, DistrictName, TalukName, MandalName, FamilyPresent, CampPlace, VillageName, CampNumber, MobileNumber, DoorNumber, EntryDate, SlNoServis, Name, Gender,
    //     Age, SystemicIllness, AlreadywearGlass, CataractLeftEye, VisualAcuityLeft, VisualAcuityRight, HistoryOfHyperTension, HistoryDiabetic

    // }));

    // ExcelDownload(excelDownload, "CbbfSurveyReport");
  };
  const filteredObjectArray = (val) => {
    setFilterValue(val);

    setPaginationModel({ ...paginationModel, page: 0 });
  };

  // hospital based district id handler
  const callHospitalBasedDist = (val) => {
    setHospital(val);
    setDistrict([]);
  };

  return (
    <div className="main-report">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px 0",
        }}
      >
        <Typography variant="h5" color="secondary" className="text-left">
          {/* CBBF Survey Report{" "} */}
          Volunteers Screening Survey Report
        </Typography>
        <Button
          sx={{ textTransform: "capitalize", width: "10%", margin: "0 10px" }}
          disabled={rows?.length == 0}
          size="small"
          variant="contained"
          startIcon={<FileDownloadIcon />}
          onClick={() => {
            head.forEach((data) => {
              data.isChecked = false;
            });
            setSearchValue({
              eventStart: "",
              eventEnd: "",
            });
            setDownloadTab((k) => !k);
          }}
        >
          Excel Download
        </Button>
      </div>
      <div>
        <SurveyReportFilter
          filterObject={(val) => filteredObjectArray(val)}
          district={district}
          callHospitalBasedDist={(_id) => callHospitalBasedDist(_id)}
        />
      </div>

      {downloadTab && (
        <div className="tabbackground">
          <div className="downloadtab">
            <div className="table">
              <Table>
                <TableHead>
                  <tr>
                    {/* <th><Checkbox
                      onChange={(e) => {
                        handleSelectAll(e);
                      }}
                    />
                      Select all</th> */}
                    <th> </th>
                    <th>Columns </th>
                  </tr>
                </TableHead>
              </Table>
              <div className="filter">
                <FormControl style={{ width: "90%" }} size="small">
                  <Select
                    labelId="select-label"
                    id="select"
                    variant="standard"
                    label="Select Dates"
                    value={dateSearch}
                    onChange={(e) => {
                      handleDates(e.target.value);
                    }}
                  >
                    {dates?.map((option) => (
                      <MenuItem key={option.id} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {dateSearch == "custom" && (
                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "45%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="from_date"
                          value={
                            searchValue.eventStart == ""
                              ? null
                              : searchValue.eventStart
                          }
                          inputFormat="DD/MM/YYYY"
                          onChange={(e) => {
                            const selectedDate = e.$d;
                            if (selectedDate) {
                              setSearchValue({
                                ...searchValue,
                                eventStart: selectedDate,
                              });
                            } else {
                              setSearchValue({
                                ...searchValue,
                                eventStart: null,
                              });
                            }
                          }}
                          slotProps={{
                            actionBar: {
                              actions: ["clear"],
                            },
                          }}
                          renderInput={(props) =>
                            renderInputs(props, "From date", "date")
                          }
                        />
                      </LocalizationProvider>
                    </div>
                    <div style={{ width: "45%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="from_date"
                          value={
                            searchValue.eventEnd == ""
                              ? null
                              : searchValue.eventEnd
                          }
                          inputFormat="DD/MM/YYYY"
                          onChange={(e) => {
                            setSearchValue({ ...searchValue, eventEnd: e.$d });
                          }}
                          renderInput={(props) =>
                            renderInputs(props, "To date", "date")
                          }
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                )}
              </div>
              <Table>
                <TableBody>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                  {headerValue?.map((head, index) => {
                    if (head.headerName !== "S no") {
                      return (
                        <TableRow key={index}>
                          <TableCell sx={{ textAlign: "center", width: "25%" }}>
                            <Checkbox
                              checked={head.isChecked}
                              onChange={(e) => {
                                handleChange(
                                  e,
                                  head.field,
                                  index,
                                  head.headerName
                                );
                              }}
                            />
                          </TableCell>
                          <TableCell sx={{ textAlign: "center" }}>
                            {alterScreenName(head?.headerName)}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  })}
                </TableBody>
              </Table>
            </div>
            <div className="downloadoptions">
              <Button
                variant="contained"
                onClick={() => {
                  setDownloadTab((k) => !k);
                  setDateSearch("");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={dateSearch == "" || value.length == 0}
                onClick={() => handleuploadExcel()}
              >
                Download
              </Button>{" "}
              {/*onClick={handleuploadExcel}*/}
            </div>
          </div>
        </div>
      )}

      <div className="table">
        <DataGrid
          localeText={{
            noRowsLabel: "Reports are processing. Please hold on for a moment.",
          }}
          style={{ height: "75vh", width: "95vw" }}
          className="table-pagination"
          rowCount={length}
          page={paginationModel.page}
          sx={{
            boxShadow: 2,
            overflowX: "auto",
            border: 2,
            textTransform: "capitalize",
            borderColor: "primary.light",
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            "& .super-app-theme--header": {
              backgroundColor: "LightBlue",
            },
          }}
          // autoHeight
          rows={rows}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          slots={{ toolbar: CustomToolBar }}
          paginationMode="server"
          disableDensitySelector
          ColumnFilter={<FieldWorkerFilter />}
          // disableColumnFilter
          components={{
            FilterPanel: <FieldWorkerFilter />,
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                door_status: false,
                will_for_screen: false,
                family_actual: false,
                family_present: false,
                field_worker_name: true,
                state_name: true,
                district_name: true,
                taluk_name: true,
                mandal_name: true,
                village_name: false,
                camp_number: false,
                mob_num: false,
                door_num: false,
                current_date: false,
                slNo_servis: true,
                name: true,
                gender: true,
                age: false,
                systemic_illess: false,
                already_glass_wear: true,
                cataract_left_eye: false,
                cataract_right_eye: false,
                visual_acuity_left: false,
                visual_acuity_right: false,
                history_hypertension: false,
                history_diabetic: false,
              },
            },
          }}
        />
      </div>

      {/* backdrop */}
      <BackDrop open={backDropOpen} />
    </div>
  );
};

export default CbbfSurveyReport;
